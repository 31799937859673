import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import { Row, Col } from "react-bootstrap";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import DropdownFilter from "../DropDownFilter";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 5,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  border: "1px solid #bfbfc0",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function GenericFilter({ filters, searchCb, addCb, dropdownCb, options, statusLabel, userAdditionDisabled }) {
  return (
    <Row className="p-0 g-1 mb-2">
      <Col xs={12} md={filters ? 4 : 8}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            onChange={searchCb}
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
      </Col>
      {options &&
        <Col xs={12} md={2}>
          <DropdownFilter
            options={options}
            onChange={(_, value) => dropdownCb(value?.id)}
            label={statusLabel}
            name={"status"}
          />
        </Col>
      }
      {filters}
      {!userAdditionDisabled &&
        <Col xs={12} md={2} className="d-none d-md-block position-relative">
          <Fab
            color="primary"
            aria-label="add"
            size="medium"
            className="d-none d-md-block"
            onClick={addCb}
            sx={{ position: "absolute", bottom: 0, right: 0 }}
          >
            <AddIcon />
          </Fab>
        </Col>
      }
    </Row>
  );
}
