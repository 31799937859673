import { BASE_URL, fetchWithErrorHandling } from "./helper";

export const loginApi = async (credentials) => {
  const response = await fetchWithErrorHandling(`${BASE_URL}/auth/login`, {
    method: "POST",
    body: JSON.stringify(credentials),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Login failed");
  }
  return await response.json();
};

export const logoutApi = async () => {
  const response = await fetchWithErrorHandling(`${BASE_URL}/auth/logout`, {
    method: "GET"
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Logout failed");
  }
  return await response.json();
};

export const accountApi = async () => {
  const response = await fetchWithErrorHandling(`${BASE_URL}/user/account`, {
    method: "GET"
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to load account");
  }
  return await response.json();
};
