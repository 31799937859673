import { BASE_URL, fetchWithErrorHandling } from "./helper";

export const fetchCategoriesApi = async (value, status, page, pageSize) => {
  try {
    let apiUrl = `${BASE_URL}/category/all?`;
    if (value) apiUrl += `value=${value}&`;
    if (status) apiUrl += `status=${status}&`;
    if (page) apiUrl += `page=${page}&`;
    if (pageSize) apiUrl += `pageSize=${pageSize}`;
    const response = await fetchWithErrorHandling(apiUrl, { method: "GET" });
    if (!response.ok) throw new Error("Failed to fetch categories");
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllCategoriesApi = async () => {
  try {
    const response = await fetchWithErrorHandling(`${BASE_URL}/category`, { method: "GET" });
    if (!response.ok) throw new Error("Failed to fetch categories");
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoryByIdApi = async (id) => {
  try {
    const response = await fetchWithErrorHandling(
      `${BASE_URL}/category/${id}`,
      { method: "GET" }
    );

    if (!response.ok) throw new Error("Failed to add product");
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const addCategoryApi = async (payload) => {
  try {
    payload["is_main"] = true;
    payload["is_active"] = true;
    const response = await fetchWithErrorHandling(`${BASE_URL}/category`, {
      method: "POST",
      body: JSON.stringify(payload),
    });

    if (!response.ok) throw new Error("Failed to add Category");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
