export const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";

export const getToken = () => {
  return sessionStorage.getItem("token");
};

export const headers = (isFormData) => {
  const defaultHeaders = {
    Authorization: `Bearer ${getToken()}`,
  };
  if (!isFormData) {
    defaultHeaders["Content-Type"] = "application/json";
  }
  return defaultHeaders;
};

export const fetchWithErrorHandling = async (
  url,
  options = {}
) => {
  try {
    const isFormData = options.body instanceof FormData;
    const response = await fetch(url, {
      ...options,
      headers: headers(isFormData),
    });
    return response;
  } catch (error) {
    console.error("Fetch error: ", error);
    throw error;
  }
};
