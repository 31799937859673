import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { saveProductImage } from '../../store/slices/productSlice'
import {
    InputLabel,
    FormControl,
    OutlinedInput,
    Button as ButtonMui,
    Select,
    MenuItem
} from "@mui/material";
import Loader from "../../components/Loader";
import withToast from "../../hocs/withToast";
import Dropzone from "../../components/DropZone";

const CategoryForm = ({ notify, modalClose, id = 0, CategoryValidation, saveCategory, categoryRequired }) => {
    const dispatch = useDispatch();
    const { loading, currentCategory, categories } = useSelector((state) => state.categories);
    const { loading: _loading, currentSubCategory } = useSelector((state) => state.subCategories);

    const formik = useFormik({
        initialValues: {
            name: currentCategory?.name || "",
            id: currentCategory?.id || 0,
            images: currentCategory?.images || [],
            category_id: currentSubCategory?.id || ""
        },
        validationSchema: CategoryValidation,
        onSubmit: (values) => {
            saveCategory(values)
        },
    });

    const handleImage = (image) => {
        // dispatch(saveProductImage({id: null, image})).then(data => {
        // })
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-name">Name</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-name"
                    type={"text"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    name={"name"}
                    label="Name"
                />
            </FormControl>
            {categoryRequired &&
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-size">
                        Choose Category
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formik.values.category_id}
                        label="Choose Category"
                        name={"category_id"}
                        onChange={(op) => formik.setFieldValue("category_id", op.target.value)}
                    >
                        {categories.map((ele, index) => (
                            <MenuItem value={ele.id} key={`categories_idx${index}`}>
                                {ele.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
            <Dropzone
                currentImages={currentCategory?.images || []}
                saveImage={handleImage}
            />
            <div className="mt-2 text-center">
                <ButtonMui
                    variant="contained"
                    disabled={!formik.isValid}
                    size="large"
                    type="submit"
                >
                    Save Category
                </ButtonMui>
            </div>
            <Loader loading={loading || _loading} />
        </form>
    );
};

export default withToast(CategoryForm);
