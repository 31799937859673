import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "./styles.css";

function Dropzone({ saveImage, currentImages, removeImage }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      saveImage(acceptedFiles);
    },
    [saveImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const removeFile = (fileToRemove) => {
    removeImage(fileToRemove)
  };

  return (
    <div className="dropzone-container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="text-center">
          <p className="dropzone-content">
            Drag’n’drop some files here, or click to select files
          </p>
        </div>
      </div>

      <div className="preview-container mt-3">
        {currentImages.map((file, index) => (
          <div key={index} className="preview-item">
            <img src={file.preview} alt="preview" className="preview-image" />
            <button
              type={"button"}
              className="remove-button"
              onClick={() => removeFile(file)}
            >
              ×
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dropzone;
