import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import './styles.css'

const GenericModal = ({ modalShow, modalClose, modalHeader, modalBody, modalAlign }) => {
    const [show, setShow] = useState(modalShow);

    const handleModalClose = () => {
        setShow(false)
        modalClose();
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={() => handleModalClose()}
                dialogClassName={`modal-90w ${modalAlign ? 'right' :''}`}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {modalHeader}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalBody}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default GenericModal;
