import { BASE_URL, fetchWithErrorHandling } from "./helper";

export const fetchSubCategoriesApi = async (
  value,
  status,
  category,
  page,
  pageSize
) => {
  try {
    let apiUrl = `${BASE_URL}/subcategory/all?`;
    if (value) apiUrl += `value=${value}&`;
    if (category) apiUrl += `category=${category}&`;
    if (status) apiUrl += `status=${status}&`;
    if (page) apiUrl += `page=${page}&`;
    if (pageSize) apiUrl += `pageSize=${pageSize}`;
    const response = await fetchWithErrorHandling(apiUrl, { method: "GET" });
    if (!response.ok) throw new Error("Failed to fetch subcategories");
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllSubCategoriesApi = async () => {
  try {
    const response = await fetchWithErrorHandling(`${BASE_URL}/subcategory`, { method: "GET" });
    if (!response.ok) throw new Error("Failed to fetch subcategories");
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const getSubCategoryByIdApi = async (id) => {
  try {
    const response = await fetchWithErrorHandling(
      `${BASE_URL}/subcategory/${id}`,
      { method: "GET" }
    );

    if (!response.ok) throw new Error("Failed to add product");
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const addSubCategoryApi = async (payload) => {
  try {
    payload["is_main"] = true;
    payload["is_active"] = true;
    const response = await fetchWithErrorHandling(`${BASE_URL}/subcategory`, {
      method: "POST",
      body: JSON.stringify(payload)
    });

    if (!response.ok) throw new Error("Failed to add Category");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
