import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoriesAsync } from "../../store/slices/catergorySlice";
import GenericTable from "../../components/GenricTable";
import GenericFilter from "../../components/GenericFilter";
import { StatusOptions } from '../../utils/constants'
import { Button, IconButton } from "@mui/material";
import { RiEdit2Fill } from "react-icons/ri";
import withToast from "../../hocs/withToast";
import {
  resetState,
  addCategoryAsync,
  fetchCategoryByIdAsync
} from "../../store/slices/catergorySlice";

import { BASE_URL } from "../../api/helper";
import Loader from "../../components/Loader";
import GenericModal from "../../components/Modal";
import CategoryForm from "./CategoryForm";
import { CategoryValidation } from "./CategoryValidation";

const columns = [
  { id: "image", label: "Image", minWidth: 50 },
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "is_main",
    label: "Main",
    minWidth: 40,
    align: "left"
  },
  {
    id: "is_active",
    label: "Status",
    minWidth: 50,
    align: "left",
  },
  {
    id: "action",
    label: "Actions",
    minWidth: 40,
    align: "center",
  },
];

const CategoryManagement = ({ notify }) => {
  const dispatch = useDispatch();
  const { categories = [], totalCount, loading } = useSelector((state) => state.categories);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [lastListLoadTime, setLastListLoadTime] = useState(new Date());
  const [filterValue, setFilterValue] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(
      fetchCategoriesAsync({
        value: filterValue,
        status: statusFilter,
        page,
        pageSize
      })
    );
  }, [
    filterValue,
    statusFilter,
    page,
    pageSize,
    lastListLoadTime
  ])

  const handleCategoryModal = (show, reloadList = false) => {
    setShowAddCategoryModal(show);
    if (reloadList) setLastListLoadTime(new Date());
  };

  const renderActionButtons = (data) => {
    return (
      <div className="d-flex">
        <IconButton
          color="primary"
          aria-label="edit"
          onClick={() => {
            startEdit(data);
          }}
          size="large"
          sx={{ padding: 0 }}
        >
          <RiEdit2Fill fontSize="inherit" />
        </IconButton>
      </div>
    );
  };

  const updatePageSetings = (_page, _rowsPerPage) => {
    if (_page !== page) setPage(_page);
    if (_rowsPerPage !== pageSize) setPageSize(_rowsPerPage);
  };

  const startEdit = (id) => {
    if (!id) {
      dispatch(resetState());
      handleCategoryModal(true, false);
    }
    else {
      dispatch(fetchCategoryByIdAsync(id))
        .unwrap()
        .then((data) => {
          handleCategoryModal(true, false);
        })
        .catch((err) => {
          console.error(err);
          notify(err.message || "Category not found", "error");
        });
    }
  };

  const rowsWithAction = (rows) => {
    return rows.map((e) => ({
      ...e,
      action: renderActionButtons(e.id),
      image: (
        <img
          src={BASE_URL + "/doc/i/" + e.image_url}
          alt={e.name}
          style={{ width: "100px" }}
        />
      ),
    }));
  };

  const handleSaveCategory = (values) => {
    dispatch(addCategoryAsync(values))
      .unwrap()
      .then(() => {
        notify("Product Saved", "success");
        handleCategoryModal(false, true);
      })
      .catch((err) => {
        console.error({err})
        notify(err.message || "Failed to save product", "error");
      });
  }

  return (
    <div>
      <h3>Categories</h3>
      <div>
        <GenericFilter
          searchCb={(e) => setFilterValue(e.target.value)}
          addCb={() => startEdit(0)}
          statusLabel={'Status...'}
          dropdownCb={(e) => setStatusFilter(e)}
          options={StatusOptions}
        />
      </div>
      <GenericTable
        columns={columns}
        rows={rowsWithAction(categories)}
        rowCount={totalCount}
        cb={updatePageSetings}
        addCb={() => startEdit(0)}
      />
      {showAddCategoryModal && (
        <GenericModal
          modalShow={showAddCategoryModal}
          modalClose={() => handleCategoryModal(false, false)}
          modalHeader={"Add Category"}
          modalBody={
            <CategoryForm
              CategoryValidation={CategoryValidation}
              saveCategory={handleSaveCategory}
            />
          }
        />
      )}
      <Loader loading={loading} />
    </div>
  );
};

export default withToast(CategoryManagement);
