import { BASE_URL, fetchWithErrorHandling } from "./helper";

export const fetchProductsApi = async (
  value,
  status,
  category_id,
  subcategory_id,
  page,
  pageSize
) => {
  try {
    let apiUrl = `${BASE_URL}/product?`;
    if (value) apiUrl += `value=${value}&`;
    if (status) apiUrl += `status=${status}&`;
    if (category_id) apiUrl += `category_id=${category_id}&`;
    if (subcategory_id) apiUrl += `subcategory_id=${subcategory_id}&`;
    if (page) apiUrl += `page=${page}&`;
    if (pageSize) apiUrl += `pageSize=${pageSize}`;

    const response = await fetchWithErrorHandling(apiUrl, { method: "GET" });

    if (!response.ok) throw new Error("Failed to fetch products");
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const addProductApi = async (productData) => {
  try {
    const response = await fetchWithErrorHandling(`${BASE_URL}/product`, {
      method: "POST",
      body: JSON.stringify(productData),
    });

    if (!response.ok) throw new Error("Failed to add product");
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const uploadImage = async (id, formdata) => {
  try {
    const response = await fetchWithErrorHandling(
      `${BASE_URL}/product/image/${id}`,
      {
        method: "POST",
        body: formdata,
      },
      true
    );

    if (!response.ok) throw new Error("Failed to add product");
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const updateProductById = async (id) => {
  try {
    const response = await fetchWithErrorHandling(
      `${BASE_URL}/product/update-status/${id}`,
      { method: "PUT" }
    );

    if (!response.ok) throw new Error("Failed to add product");
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getProductByIdApi = async (id) => {
  try {
    const response = await fetchWithErrorHandling(`${BASE_URL}/product/${id}`, {
      method: "GET",
    });

    if (!response.ok) throw new Error("Failed to add product");
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
