import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  OutlinedInput,
  Button as ButtonMui,
} from "@mui/material";

import withNavigate from "../../hocs/withNavigate";

import Loader from "../../components/Loader";

import { loginAsync } from "../../store/slices/authSlice";

import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { FaCircleUser } from "react-icons/fa6";
import { loginSchema } from "./validation";
import "./styles.css";

const Login = ({ navigate }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { loading, error, isAuthenticated } = useSelector(
    (state) => state.auth
  );
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(loginAsync(values))
        .unwrap()
        .then(() => {
          navigate("/dashboard");
        })
        .catch((err) => {
          console.error("Login failed:", err);
        });
    },
  });

  useEffect(() => {
    if (isAuthenticated) loadUser();
  }, []);

  const loadUser = async () => {
    try {
      navigate("/dashboard");
    } catch (err) {
      console.error(err);
    }
  };

  const getError = () => {
    if (formik.touched.email && formik.errors.email)
      return <p className="error p-0">{formik.errors.email}</p>;
    else if (formik.touched.password && formik.errors.password)
      return <p className="error p-0">{formik.errors.password}</p>;
    else if (error) return <p className="error p-0">{error.message}</p>;
    else return null;
  };

  const handleClickShowPassword = () =>
    setShowPassword((showPassword) => !showPassword);

  return (
    <Row className="vh-100 m-0 p-0">
      <Col
        xs={12}
        md={6}
        lg={7}
        xxl={8}
        className="mx-auto d-none d-md-flex align-items-center"
      >
        <img
          className="regiter-img"
          src="./assets/images/login.jpg"
          alt="login-img"
          width={"100%"}
        />
      </Col>
      <Col xs={12} md={6} lg={5} xxl={4} className="login">
        <div className="container">
          <div className="text-center mb-4">
            <FaCircleUser size={100} className="me-2 mb-2" />
            <img src="./assets/images/logo.png" alt="logo" width={'80%'}/>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-username">
                Username
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-username"
                type={"text"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                name={"email"}
                autoComplete="new-username"
                endAdornment={
                  <InputAdornment position="end" sx={{ pl: "6px" }}>
                    <AccountCircle sx={{ color: "action.active" }} />
                  </InputAdornment>
                }
                label="Username"
                error={
                  !!((formik.touched.email && formik.errors.email) || error)
                }
              />
            </FormControl>
            <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="new-password"
                value={formik.values.password}
                name={"password"}
                endAdornment={
                  <InputAdornment position="end" sx={{ pr: "3px" }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                error={
                  !!(
                    (formik.touched.password && formik.errors.password) ||
                    error
                  )
                }
              />
            </FormControl>
            {getError()}
            <div className="mt-3 text-center">
              <ButtonMui
                variant="contained"
                disabled={!formik.isValid}
                size="large"
                type="submit"
              >
                LOGIN
              </ButtonMui>
              <div className="mt-2">
                Don't have an account?{" "}
                <Link className="bold" to="/register">
                  Register
                </Link>
              </div>
            </div>
          </form>
        </div>
      </Col>
      <Loader loading={loading} />
    </Row>
  );
};

export default withNavigate(Login);
