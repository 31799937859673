import React, { useEffect, useState } from "react";
import { Nav, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { accountAsync } from "../../store/slices/authSlice";
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaHome,
  FaBox,
  FaUsers,
  FaShapes,
} from "react-icons/fa";

import "./style.css";

const Sidebar = () => {
  const location = useLocation();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [collapsed, setCollapsed] = useState(false);


  const toggleCollapse = () => setCollapsed(!collapsed);
  const checkIfLinkIsActive = (pathname) => {
    if (
      pathname === "/dashboard" &&
      (location.pathname === "" || location.pathname === "/")
    )
      return true;
    return pathname === location.pathname;
  };

  return (
    <div
      className={`d-flex flex-column position-sticky sidebar ${collapsed ? "sidebar-collapsed" : ""
        }`}
    >
      <div className="d-flex flex-row w-100">
        <div className={"logo-container"}>
          <img
            src={
              collapsed
                ? "./assets/images/logo-sm.png"
                : "./assets/images/logo.png"
            }
            className="sidebar-logo"
            alt="logo"
          />
        </div>
        <div className="mt-3 floating-anchor">
          <Button variant="link" onClick={toggleCollapse}>
            {collapsed ? (
              <FaChevronCircleRight size={25} color="#4c4d9d" />
            ) : (
              <FaChevronCircleLeft size={25} color="#4c4d9d" />
            )}
          </Button>
        </div>
      </div>
      <Nav className="flex-column">
        <Nav.Link
          as={Link}
          to="/dashboard"
          className={`d-flex align-items-center justify-sm-content-center ${checkIfLinkIsActive("/dashboard") ? "active" : ""
            }`}
        >
          <FaHome
            className="sidebarIcon"
            style={collapsed && { height: 20, width: 20 }}
          />
          <div className="ms-3 sidebarIcon">{!collapsed && "Dashboard"}</div>
        </Nav.Link>

        <Nav.Link className={`d-flex align-items-center ${checkIfLinkIsActive("/categories") ? "active" : ""}`} as={Link} to="/categories" onClick={() => setShowSubMenu((state) => !state)}>
          <FaShapes className="sidebarIcon" style={collapsed && { height: 20, width: 20 }} />
          <div className="ms-3 sidebarIcon">{!collapsed && "Categories"}</div>
          {!collapsed && (showSubMenu ? <FaCaretUp className="ms-auto sidebarIcon" /> : <FaCaretDown className="ms-auto sidebarIcon" />)}
        </Nav.Link>
        {showSubMenu && !collapsed && (
          <div className="pl-4">
            <Nav.Link
              as={Link}
              to="/categories"
              className={`d-flex align-items-center ${checkIfLinkIsActive("/categories") ? "active" : ""
                }`}
            >
              <div className="ms-3 sidebarIcon">{!collapsed && "Main Categories"}</div>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/sub-categories"
              className={`d-flex align-items-center ${checkIfLinkIsActive("/sub-categories") ? "active" : ""
                }`}
            >
              <div className="ms-3 sidebarIcon">{!collapsed && "Sub Categories"}</div>
            </Nav.Link>
          </div>
        )}
        <Nav.Link
          as={Link}
          to="/products"
          className={`d-flex align-items-center ${checkIfLinkIsActive("/products") ? "active" : ""
            }`}
        >
          <FaBox
            className="sidebarIcon"
            style={collapsed && { height: 20, width: 20 }}
          />
          <div className="ms-3 sidebarIcon">{!collapsed && "Products"}</div>
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/users"
          className={`d-flex align-items-center ${checkIfLinkIsActive("/users") ? "active" : ""
            }`}
        >
          <FaUsers
            className="sidebarIcon"
            style={collapsed && { height: 20, width: 20 }}
          />
          <div className="ms-3 sidebarIcon">{!collapsed && "Users"}</div>
        </Nav.Link>
      </Nav>
      <div className="mt-auto p-3 text-body-tertiary text-center">
        {collapsed ? "© UE" : "© Universal Exports"}
      </div>
    </div>
  );
};

export default Sidebar;
