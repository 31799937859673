import React, { useEffect, useState } from "react";
import GenericTable from "../../components/GenricTable";
import GenericFilter from "../../components/GenericFilter";
import Button from "@mui/material/Button";
import {
  fetchUsersAsync,
  updateUserStatusByIdAsync,
} from "../../store/slices/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { StatusOptions } from "../../utils/constants";
import { TiTick } from "react-icons/ti";
import { MdNotInterested } from "react-icons/md";
import Loader from "../../components/Loader";

const columns = [
  { id: "name", label: "Name", minWidth: 100, align: "center" },
  { id: "email", label: "Email", minWidth: 150, align: "center" },
  { id: "role", label: "Role", minWidth: 100, align: "center" },
  {
    id: "mobile",
    label: "Mobile",
    minWidth: 170,
    align: "center",
  },
  {
    id: "is_active",
    label: "Status",
    minWidth: 100,
    align: "center",
  },
  {
    id: "is_verified",
    label: "Verified",
    minWidth: 100,
    align: "center",
  },
  {
    id: "action",
    label: "Actions",
    minWidth: 40,
    align: "center",
  },
];

const Users = () => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [lastListLoadTime, setLastListLoadTime] = useState(new Date());
  const { users, totalCount, loading } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(
      fetchUsersAsync({
        value: filterValue,
        status: statusFilter,
        page,
        pageSize,
      })
    );
  }, [filterValue, statusFilter, page, pageSize, lastListLoadTime]);

  const renderActionButtons = (id, status) => {
    return (
      <Button
        aria-label="edit"
        onClick={() => handleStatusChange({ id: id, status })}
      >
        <MdNotInterested fontSize="x-large" />
      </Button>
    );
  };

  const handleStatusChange = (data) => {
    dispatch(updateUserStatusByIdAsync(data));
    setLastListLoadTime(new Date());
  };

  const rowsWithAction = (rows) => {
    return rows.map((e) => ({
      ...e,
      is_active: e.is_active && <TiTick fontSize="x-large" />,
      is_verified: e.is_verified && <TiTick fontSize="x-large" />,
      action: renderActionButtons(e.id, { status: false }),
      // image:
      //   <img
      //     src={BASE_URL + "/doc/i/" + e?.images[0]?.image_path}
      //     alt={e.name}
      //     style={{ width: "100px" }}
      //   />
      // ,
    }));
  };

  return (
    <div className="container mt-4">
      <GenericFilter
        statusLabel={"Status..."}
        searchCb={(e) => setFilterValue(e.target.value)}
        dropdownCb={(e) => setStatusFilter(e)}
        options={StatusOptions}
        userAdditionDisabled={true}
      />
      <GenericTable
        columns={columns}
        rows={rowsWithAction(users)}
        maxHeight={"70vh"}
        cb={() => {}}
        rowCount={totalCount}
      />
      <Loader loading={loading} />
    </div>
  );
};

export default Users;
