import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import productReducer from './slices/productSlice';
import categoryReducer from './slices/catergorySlice';
import subCategoryReducer from './slices/subCategorySlice';
import userReducer from './slices/userSlice';


const store = configureStore({
  reducer: {
    auth: authReducer,
    products: productReducer,
    categories: categoryReducer,
    subCategories: subCategoryReducer,
    users: userReducer,
  },
});

export default store;
