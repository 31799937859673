import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function DropdownFilter({ options, label, onChange }) {
  return (
    <Autocomplete
      disablePortal
      size="small"
      options={options}
      sx={{ width: "100%" }}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
