import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCategoriesApi, getCategoryByIdApi, addCategoryApi, fetchAllCategoriesApi } from "../../api/category";

export const fetchCategoriesAsync = createAsyncThunk(
  "categories/fetchCategoriesAsync",
  async (
    { value, status, page, pageSize },
    { rejectWithValue }
  ) => {
    try {
      const categories = await fetchCategoriesApi(value, status, page + 1, pageSize);
      return categories;
    }
    catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAllCategoriesAsync = createAsyncThunk(
  "categories/fetchAllCategoriesAsync",
  async (
    _,
    { rejectWithValue }
  ) => {
    try {
      const categories = await fetchAllCategoriesApi();
      return categories;
    }
    catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCategoryByIdAsync = createAsyncThunk(
  "categories/fetchCategoryByIdAsync",
  async (id, { rejectWithValue }) => {
    try {
      const product = await getCategoryByIdApi(id);
      return product.data;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

export const addCategoryAsync = createAsyncThunk(
  "categories/addNewCategory",
  async (data, { rejectWithValue }) => {
    try {
      const category = await addCategoryApi(data);
      return category;
    }
    catch(err) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
)

const cartegorySlice = createSlice({
  name: "categories",
  initialState: {
    categories: [],
    allCategories: [],
    currentCategory: null,
    loading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {
    resetState: (state) => {
      state.currentCategory = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch Categories
      .addCase(fetchCategoriesAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchCategoriesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload.categories;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchCategoriesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // fetch all Categories
      .addCase(fetchAllCategoriesAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchAllCategoriesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allCategories = action.payload;
      })
      .addCase(fetchAllCategoriesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // add Category
      .addCase(addCategoryAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.currentCategory = action.payload;
      })
      .addCase(addCategoryAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // get Category by Id
      .addCase(fetchCategoryByIdAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchCategoryByIdAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.currentCategory = action.payload;
      })
      .addCase(fetchCategoryByIdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetState } = cartegorySlice.actions;
export default cartegorySlice.reducer;
